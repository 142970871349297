<template  lang="pug">
	v-container(class="section-version")
		v-row(justify="center")
			v-col( cols="12" lg="10" )
				
				h1(class="display-1") Logs
				br

				v-tabs(v-model="tab" right)
					v-tab(v-for="item in items" :key="item.tab") {{ item.tab }}
				br

				v-tabs-items(v-model="tab")
					v-tab-item(v-for="item in items" :key="item.tab")
						v-expansion-panels
							v-expansion-panel(v-for="pipeline in item.content" :key="pipeline.name")
								v-expansion-panel-header 
										v-row
											v-col
												h4 {{ pipeline.name }}
											v-col
												h3(class="green--text" v-if="pipeline.status == 'SUCCESS'") SUCCESS
												h3(class="red--text" v-if="pipeline.status == 'ERROR'") ERROR
											v-col
												span Last Update: {{ pipeline.last_update.substring(0, 10) }}
											v-col(v-if="pipeline.next_update != null")
												span Next Update: {{ pipeline.next_update.substring(0, 10) }}
								v-expansion-panel-content
									p Description: {{ pipeline.description }}
									p Type: {{ pipeline.type }}
									p Load Frequency: {{ pipeline.load_frequency }}
									p Rows: {{ pipeline.rows }}
									p Seconds: {{ pipeline.seconds }}
									p Message: {{ pipeline.message }}


</template>
<script>
	import LogsService from '../../services/LogsService'
	const logsService = new LogsService();

	export default {
		name: 'logs',

		data() {
			return {
				logs: null,
				tab: null,
				items: [
					{ tab: 'DB to BigQuery', content: [] },
					{ tab: 'BigQuery to DB', content: [] },
					{ tab: 'CSV', content: [] }, 
					
				],
			}
		},
		components:{
		},
		mounted(){
			this.getLogs();
		},
		methods:{
			getLogs: function() {
				logsService.fetchLogs()
				.then((response)=>{
					this.logs = response.data.data;
					var pipeline = null;
					for (pipeline in this.logs) {
						if (this.logs[pipeline].pipeline_group == 'db_to_bq') {
							this.items[0].content.push(this.logs[pipeline]);
						}
						if (this.logs[pipeline].pipeline_group == 'bq_to_db') {
							this.items[1].content.push(this.logs[pipeline]);
						} 
					}
				})
				.catch((error)=>{
					console.log("Error: No connection with logs table")
				})
				.then((response)=>{
				})
			},
		},
	}
</script>
